export const environment = {
    production: true,
    stage: 'production',
    host: 'https://app.octiga.io',
    appId: 'b7bb6b20-31a8-4c3f-9443-6df48d1d6d6d',
    bugsnag: {
        api_key: 'de4f46b3edd5e63b600ac1eb6d2c7751'
    },
    principalDisplayName: 'Octiga Cloud Security',
    stripe: {
        pk: 'pk_live_51HH61vETpOxjeWDyzJOGiZjf333LcHHhiNgFLYDHBZNUlWjA1KVMysz3ggqOTVaQnedx6TuJ6eKokDyfZiEvMiko000tvpqZIp',
        prices: {
            premium: 'price_1HOk0FETpOxjeWDyHKt4jBEo'
        }
    },
    oauth: {
        client: {
            client_id: 'b7bb6b20-31a8-4c3f-9443-6df48d1d6d6d',
            authenticate_redirect_uri: 'https://app.octiga.io/api/microsoft/oauth/authenticate',
            authorise_redirect_uri: 'https://app.octiga.io/api/microsoft/oauth/authorise',
            return_url: 'https://app.octiga.io/dashboard'
        },
        msp: {
            client_id: '90b08f8f-0cb8-420c-b8d6-afd9f2e367d4',
            authenticate_redirect_uri: 'https://app.octiga.io/api/microsoft/oauth/authenticate/msp',
            return_url: 'https://app.octiga.io/msp/dashboard'
        }
    }
};
